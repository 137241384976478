.root {
  --animation-properties: var(--content-animation-duration, var(--duration-md))
    var(--content-animation-easing, var(--easing-standard))
    var(--content-animation-delay, 0s);
  transition: all var(--animation-properties);
}

.root[data-state='open'] {
  gap: var(--trigger-gap, var(--space-2));
}
.root[data-state='closed'] {
  gap: 0;
}

.contentWrapper {
  flex: 1;
  display: grid;
  overflow: hidden;
}

.root.vertical .contentWrapper {
  transition: grid-template-rows var(--animation-properties);
  grid-template-rows: 1fr;
}

.root.vertical[data-state='open'] .contentWrapper {
  grid-template-rows: 1fr;
}
.root.vertical[data-state='closed'] .contentWrapper {
  grid-template-rows: 0fr;
}

.root.horizontal .contentWrapper {
  transition: grid-template-columns var(--animation-properties);
  grid-template-columns: 1fr;
}

.root.horizontal[data-state='open'] .contentWrapper {
  grid-template-columns: 1fr;
}
.root.horizontal[data-state='closed'] .contentWrapper {
  grid-template-columns: 0fr;
}

.root.vertical .content {
  min-height: 0;
}

.root.horizontal .content {
  min-width: 0;
}
