.root {}

.content {
  max-width: calc(60ch * var(--scaling));
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
}

.title {
  font-size: var(--font-size-5);
  font-weight: bold;
  margin-top: var(--space-2);
  margin-bottom: var(--space-3);
}

.description {
  font-size: var(--font-size-2);
  font-weight: regular;
}

.trigger {}

.close,
.cancel,
.action {}

.actions {
  padding-top: var(--space-6);
}

.content:where(:global(.rt-r-size-1)) {
  --dialog-content-padding: var(--space-1);
  border-radius: var(--radius-2);
}

.content:where(:global(.rt-r-size-2)) {
  --dialog-content-padding: var(--space-2);
  border-radius: var(--radius-2);
}

.content:where(:global(.rt-r-size-3)) {
  --dialog-content-padding: var(--space-3);
  border-radius: var(--radius-3);
}

.content:where(:global(.rt-r-size-4)) {
  --dialog-content-padding: var(--space-4);
  border-radius: var(--radius-3);
}
