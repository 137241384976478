.root {
  --content-animation-duration: var(--duration-xl);
}

.loaderDisclosure {
  margin-right: calc(-1 * var(--loader-gap));
}

.loaderDisclosure[data-state='open'] {
  --content-animation-delay: var(--loader-open-delay, 0s);
}

.loaderDisclosure[data-state='closed'] {
  --content-animation-delay: var(--loader-close-delay, 0s);
}

.loader {
  margin-right: var(--loader-gap);
}

.root:where(:global(.rt-r-size-1)) {
  --loader-gap: var(--space-1);
}
.root:where(:global(.rt-r-size-1)):where(:global(.rt-variant-ghost)) {
  --loader-gap: var(--space-1);
}
.root:where(:global(.rt-r-size-2)) {
  --loader-gap: var(--space-2);
}
.root:where(:global(.rt-r-size-2)):where(:global(.rt-variant-ghost)) {
  --loader-gap: var(--space-1);
}
.root:where(:global(.rt-r-size-3)) {
  --loader-gap: var(--space-3);
}
.root:where(:global(.rt-r-size-3)):where(:global(.rt-variant-ghost)) {
  --loader-gap: var(--space-2);
}
.root:where(:global(.rt-r-size-4)) {
  --loader-gap: var(--space-3);
}
.root:where(:global(.rt-r-size-4)):where(:global(.rt-variant-ghost)) {
  --loader-gap: var(--space-2);
}
