.modalContent {
  gap: var(--space-4);
  max-width: calc(45ch * var(--scaling));
}

.logo,
.heading {
  align-self: center;
}

:global(.rt-DialogOverlay):has(.modalContent)::after {
  --color-overlay: var(--color-page-background);
}
