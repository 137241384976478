.snippet {
  display: inline-flex;
  position: relative;
  border-radius: var(--radius-2);
  font-size: var(--font-size-1);
  padding-left: var(--space-2);
  max-width: 100%;
}

.soft {
  background-color: var(--accent-a3);
}

.outline {
  box-shadow: inset 0 0 0 1px var(--accent-a8);
}

.ghost {
}

.snippetLabel {
  color: var(--accent-a11);
  white-space: nowrap;
}

.snippetContent {
  color: var(--accent-a11);
  white-space: pre-wrap;
  margin: var(--space-2) var(--space-1);

  /* This block of madness is to implement the maxLines prop, clamping the
     snippet content at no more than so many lines. For more info, see:
     https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines); /* number of lines to show */
  line-clamp: var(--max-lines);
  overflow: hidden;
}

.copyToClipboard {
  align-self: flex-start;
}

.copyIcon,
.checkIcon {
  transition: opacity 0.2s;
}

.copyIcon {
  opacity: 1;
}

.copyIcon[data-copied='true'] {
  opacity: 0;
}

.checkIcon {
  opacity: 0;
  position: absolute;
}

.checkIcon[data-copied='true'] {
  opacity: 1;
}
